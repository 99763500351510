<template>
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6875 0.5C13.3984 0.5 14 1.10156 14 1.8125V9.6875C14 10.4258 13.3984 11 12.6875 11H1.3125C0.574219 11 0 10.4258 0 9.6875V1.8125C0 1.10156 0.574219 0.5 1.3125 0.5H12.6875ZM12.6875 1.8125H1.3125V2.93359C1.91406 3.45312 2.89844 4.21875 4.97656 5.85938C5.44141 6.21484 6.34375 7.08984 7 7.0625C7.62891 7.08984 8.53125 6.21484 8.99609 5.85938C11.0742 4.21875 12.0586 3.45312 12.6875 2.93359V1.8125ZM1.3125 9.6875H12.6875V4.62891C12.0586 5.12109 11.1562 5.83203 9.81641 6.89844C9.1875 7.36328 8.14844 8.40234 7 8.375C5.82422 8.40234 4.75781 7.36328 4.15625 6.89844C2.81641 5.83203 1.91406 5.12109 1.3125 4.62891V9.6875Z"
      fill="currentColor"
    />
  </svg>
</template>
